import React from "react";
import { FormattedMessage } from "react-intl";

const FormError = () => {
  return (
    <div className="error">
      <h3>
        <FormattedMessage id="error.headline" />
      </h3>
      <p>
        <FormattedMessage id="error.message" />
      </p>
    </div>
  );
};

export default FormError;
