import React from "react";
import GetLocations from "../queries/GetLocations";
import { useIntl, FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import classNames from "classnames";

import LoadingMarkup from "../components/loadingmarkup";

const FormPhaseLocation = ({ handleChange, selectedLocation }) => {
  const intl = useIntl();
  const t = (translation_key) => intl.formatMessage({ id: translation_key });
  const { loading, error, data } = useQuery(GetLocations);

  const onLocationClick = (locationId) => {
    handleChange({ location: locationId });
  };

  if (loading) {
    return <LoadingMarkup />;
  }

  if (error) {
    return (
      <div className="error">
        <h3>
          <FormattedMessage id={error.headline} />
        </h3>
        <p>
          <FormattedMessage id={error.message} />
        </p>
      </div>
    );
  }

  return (
    <div className="form-section form-start-section">
      <div className="logo">
        <img src="/img/inklabsWortmarke_weiß.svg" alt="inklabs-logo" />
      </div>
      <p>
        <FormattedMessage id="location.intro.1" />{" "}
        <strong>
          <FormattedMessage id="location.intro.2" />
        </strong>{" "}
        <FormattedMessage id="location.intro.3" />{" "}
        <u>
          <FormattedMessage id="location.intro.4" />
        </u>{" "}
        <FormattedMessage id="location.intro.5" />
      </p>

      <ul className="form-row appointment-locations">
        {data.entityQuery.items.map((item, index) => {
          if (item.fieldShowInCustomerForm)
            return (
              <li
                className="col-md-6 col-12"
                key={index}
                onClick={(event) => {
                  event.preventDefault();
                  onLocationClick(item.id);
                }}
              >
                <div
                  className={classNames({
                    "appointment-location": true,
                    "appointment-tile": true,
                    selected: item.id === selectedLocation,
                  })}
                >
                  {item.fieldFrontendImage && (
                    <img
                      className="appointment-location-image"
                      src={item.fieldFrontendImage.derivative.urlPath}
                      alt={item.label}
                    />
                  )}
                  {!item.fieldFrontendImage && (
                    <img
                      className="appointment-location-image"
                      src="/img/inklabsWortmarke_weiß.svg"
                      alt={item.label}
                    />
                  )}
                  <div className="appointment-location-label">{item.label}</div>
                </div>
              </li>
            );
        })}
      </ul>
    </div>
  );
};

FormPhaseLocation.propTypes = {
  handleChange: PropTypes.func,
  selectedLocation: PropTypes.string,
};

export default FormPhaseLocation;
