import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { TextInput } from "carbon-components-react";
import classNames from "classnames";

const FormPhasePersonalInfo = ({
  handleChange,
  firstname,
  lastname,
  email,
  privacy,
  phone,
}) => {
  const intl = useIntl();
  const t = (translation_key) => intl.formatMessage({ id: translation_key });

  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required(t("validator.firstname.missing")),
    lastname: Yup.string().required(t("validator.lastname.missing")),
    email: Yup.string()
      .email("validator.email.parse")
      .required(t("validator.email.missing")),
    phone: Yup.string()
      .required(t("validator.phone.missing"))
      .phone("DE", false, t("validator.phone.parse")),
    privacy: Yup.bool().oneOf([true], t("validator.privacy.required")),
  });

  const submitForm = (data) => {
    const submitValue = {
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      privacy: data.privacy,
      phone: data.phone,
    };
    // console.log("SUBMIT FORM ", JSON.stringify(submitValue, null, 4));
    handleChange(submitValue);
  };

  return (
    <div className="form-section form-personal-info">
      <h1>{t("Deine Daten")}</h1>
      <p>
        <span className="appointment-text-bold">{t("yourdata.intro.1")}</span>{" "}
        {t("yourdata.intro.2")}
      </p>

      <Formik
        enableReinitialize
        initialValues={{
          firstname: firstname,
          lastname: lastname,
          email: email,
          privacy: privacy,
          phone: phone,
        }}
        validationSchema={validationSchema}
        onSubmit={submitForm}
      >
        {({ errors, touched, handleChange, handleBlur }) => {
          return (
            <Form>
              <div className="appointment-personal-info">
                <div className="form-group">
                  <label
                    htmlFor="appointment-form-firstname"
                    className="required appointment-personal-info-label"
                  >
                    {t("forms.firstname.label")}
                  </label>
                  <TextInput
                    placeholder={t("forms.firstname.placeholder")}
                    id="appointment-form-firstname"
                    labelText={t("forms.firstname.label")}
                    hideLabel
                    className={classNames({
                      "form-control": true,
                      firstname: true,
                      "is-invalid": !!errors.firstname,
                    })}
                    name="firstname"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <ErrorMessage name="firstname">
                    {(error) => <p className="text-danger">{error}</p>}
                  </ErrorMessage>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="appointment-form-lastname"
                    className="required appointment-personal-info-label"
                  >
                    {t("forms.lastname.label")}
                  </label>
                  <TextInput
                    placeholder={t("forms.lastname.placeholder")}
                    id="appointment-form-lastname"
                    labelText={t("forms.lastname.label")}
                    hideLabel
                    className={classNames({
                      "form-control": true,
                      lastname: true,
                      "is-invalid": !!errors.lastname,
                    })}
                    name="lastname"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <ErrorMessage name="lastname">
                    {(error) => <p className="text-danger">{error}</p>}
                  </ErrorMessage>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="appointment-form-email"
                    className="required appointment-personal-info-label"
                  >
                    {t("forms.email.label")}
                  </label>
                  <TextInput
                    placeholder={t("forms.email.placeholder")}
                    id="appointment-form-email"
                    labelText={t("forms.email.label")}
                    hideLabel
                    className={classNames({
                      "form-control": true,
                      email: true,
                      "is-invalid": !!errors.email,
                    })}
                    name="email"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <ErrorMessage name="email">
                    {(error) => <p className="text-danger">{error}</p>}
                  </ErrorMessage>
                </div>

                <div className="form-group">
                  <label
                    htmlFor="appointment-form-phone"
                    className="required appointment-personal-info-label"
                  >
                    {t("forms.phone.label")}
                  </label>
                  <TextInput
                    placeholder={t("forms.phone.placeholder")}
                    id="appointment-form-phone"
                    labelText={t("forms.phone.label")}
                    hideLabel
                    className={classNames({
                      "form-control": true,
                      phone: true,
                      "is-invalid": !!errors.phone,
                    })}
                    name="phone"
                    onChange={(e) => handleChange(e)}
                    onBlur={(e) => handleBlur(e)}
                  />
                  <ErrorMessage name="phone">
                    {(error) => <p className="text-danger">{error}</p>}
                  </ErrorMessage>
                </div>

                <div className="form-group checkbox-with-label">
                  <label
                    htmlFor="privacy"
                    className="required appointment-personal-info-label"
                  >
                    {t("forms.privacy.label")}
                  </label>
                  <Field
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    className={
                      "form-check-input " +
                      (errors.privacy && touched.privacy ? " is-invalid" : "")
                    }
                    onChange={(event) => handleChange(event)}
                  />
                  <label htmlFor="privacy" className="label-description">
                    {t("forms.privacy.label.1")}
                    <a
                      href="https://www.inklabs.de/rechtliches/datenschutz"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      {t("forms.privacy.label.2")}
                    </a>
                    .
                  </label>
                  <ErrorMessage name="privacy">
                    {(error) => <p className="text-danger">{error}</p>}
                  </ErrorMessage>
                </div>
              </div>

              <div className="button-grp">
                <button
                  className="next appointment-button appointment-button--green"
                  type="submit"
                >
                  {t("Termin buchen")}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

FormPhasePersonalInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  firstname: PropTypes.string,
  lastname: PropTypes.string,
  email: PropTypes.string,
  privacy: PropTypes.bool,
  phone: PropTypes.string,
};

export default FormPhasePersonalInfo;
