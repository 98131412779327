import React from "react";
import Dropzone from "react-dropzone";

const FormPhaseTattoo = ({
  alert,
  deleteFiles,
  deleteSpecificFile,
  disableSubmit,
  fields,
  handleChange,
  nextPhase,
  onDrop,
  previousPhase,
  t,
}) => {
  return (
    <div className="form-section form-tattoo">
      <h1>{t("tattoo.headline")}</h1>
      <p>
        <span className="appointment-text-bold">{t("tattoo.intro.1")}</span>{" "}
        {t("tattoo.intro.2")}
      </p>
      <div className="appointment-tattoo">
        <div className="form-group text-area">
          <label
            htmlFor="appointment-tattoo-description"
            className="appointment-tattoo-label"
          >
            {t("tattoo.form.description.label")}
          </label>
          <textarea
            placeholder={t("tattoo.form.description.placeholder")}
            rows="5"
            name="message"
            id="appointment-tattoo-description"
            className="form-control"
            value={fields.message}
            onChange={(e) => handleChange(e)}
          />
        </div>

        <div className="upload_fields needsclick">
          <label
            htmlFor="appointment-tattoo-dropzone"
            className="appointment-tattoo-label"
          >
            {t("tattoo.form.dropzone.label")}
          </label>
          <Dropzone
            accept="image/jpeg, image/png, image/gif"
            className="appointment-dropzone"
            id="appointment-tattoo-dropzone"
            onDrop={onDrop}
            maxSize={2500000}
            onDropRejected={() =>
              alert.show(t("Zu viele Bilder"), { type: "error" })
            }
          >
            <div className="appointment-dropzone-placeholder">
              <div className="icon">
                <img src="/upload.svg" alt="" />
              </div>
              <div className="text">
                <p className="appointment-text-bold">
                  {t("tattoo.form.dropzone.description.1")}
                </p>
                <p className="small">
                  {t("tattoo.form.dropzone.description.2")}
                </p>
              </div>
            </div>
          </Dropzone>

          <ul className="appointment-tattoo-uploads">
            {fields.files.map((file, i) => (
              <li key={i}>
                <div
                  onClick={() => deleteSpecificFile(i)}
                  className="delete-icon"
                >
                  <img src="/cancel_download.svg" alt="remove" />
                </div>
                <p>{file.name}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="button-grp">
        <button
          className="appointment-button appointment-button--green"
          onClick={nextPhase}
        >
          {t("next")}
        </button>
        <button className="appointment-button" onClick={nextPhase}>
          {t("skip")}
        </button>
      </div>
    </div>
  );
};

export default FormPhaseTattoo;
