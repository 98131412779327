import React, { useEffect } from "react";

// Router
import { useLocation, Route, Switch, Redirect } from "react-router-dom";

// 3rd Party
import memoize from "memoize-one";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

// Redux
import { useDispatch, useSelector } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Components
import ErrorBoundary from "./error-boundary";
import InquiryForm from "./forms/inquiry-form/inquiry-form";
import Loading from "./loading";
import { i18nAction } from "./i18n/i18n-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */

const messages = {
  de: messages_de,
  en: messages_en,
};

const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.i18n.currentLanguage);

  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  const generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  useEffect(() => {
    document.body.className += `${generateMainClasses(location.pathname)}`;

    for (const locale of ["en", "de"]) {
      if (
        location.pathname === `/${locale}` ||
        location.pathname.substring(0, 4) === `/${locale}/`
      ) {
        if (currentLanguage !== locale) {
          // console.log(
          //   "%c locale: switch from %s to %s",
          //   "background-color: aqua; color: red; font-weight: bold; padding: 6px;",
          //   currentLanguage,
          //   locale
          // );
          dispatch(i18nAction(locale, false, false));
        }
        break;
      }
    }
  }, []);

  useEffect(() => {
    // Scroll to top on route change
    if (!location.hash) {
      window.scrollTo(0, 0);
      document.body.className = `${generateMainClasses(location.pathname)}`;
    }
  }, [location.pathname]);

  const alertOptions = {
    position: "top center",
    timeout: 5000,
    offset: "100px",
    transition: "scale",
  };

  return (
    <IntlProvider
      locale={currentLanguage}
      defaultLocale="de"
      messages={messages[currentLanguage]}
    >
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <Loading />
        <div className="main-content">
          <main className="main-page-content">
            <ErrorBoundary>
              <Switch>
                <Route
                  render={(props) => (
                    <InquiryForm {...props} client={props.client} />
                  )}
                  path={"/:language"}
                />
                <Redirect to={{ pathname: "/de" }} />
              </Switch>
            </ErrorBoundary>
          </main>
        </div>
      </AlertProvider>
    </IntlProvider>
  );
};

App.propTypes = {};

export default App;
