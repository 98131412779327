import gql from "graphql-tag";

const GetAppointmentTypes = gql`
  query appointmenttypes {
    entityQuery(
      entityType: TAXONOMY_TERM
      limit: 99
      offset: 0
      filter: {
        conditions: [
          { operator: EQUAL, field: "vid", value: ["appointment_type"] }
          { operator: IN, field: "field_show_in_customer_form", value: ["1"] }
        ]
      }
    ) {
      items {
        id
        label
        ... on TaxonomyTermAppointmentType {
          name
          tid
          fieldLocalOnly
          fieldShowInCustomerForm
        }
      }
    }
  }
`;

export default GetAppointmentTypes;
