class WSState {
  // Create new instances of the same class as static attributes
  static DONE = new WSState("done");
  static EMPTY = new WSState("empty");
  static ERROR = new WSState("error");
  static IDLE = new WSState("idle");
  static LOADING = new WSState("loading");

  constructor(name) {
    this.name = name;
  }
}

export default WSState;
