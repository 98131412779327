import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import PropTypes from "prop-types";
import AppointmentSource from "../types/appointment-source";
import LoadingMarkup from "./loadingmarkup";
import WSState from "../types/ws-state";
import { restHost } from "../../../config";
import FormError from "./form-error";
import { useSelector } from "react-redux";

import DatePicker, { registerLocale } from "react-datepicker";
import { de } from "date-fns/locale/de";
import { ErrorMessage, Field, Form, Formik } from "formik";
registerLocale("de", de);

const FormPhaseDateCalendar = ({
  location,
  sessionType,
  handleChange,
  appointmentSource,
  selectedDay,
  selectedTime,
}) => {
  const intl = useIntl();
  const t = (translation_key) => intl.formatMessage({ id: translation_key });

  const arrayUnique = (baseArray) => [...new Set(baseArray)];

  const defaultCalendarData = {
    month: moment().format("MM"),
    year: moment().format("YYYY"),
    dailyHours: [],
    dailyHoursState: WSState.IDLE,
    unavailableDaysOfMonth: [],
    monthlyState: WSState.IDLE,
    firstDay: moment().format("YYYY-MM-DD"),
    firstDayOfMonth: "",
    lastDayOfMonth: "",
    selectedDay: selectedDay,
    selectedTime: selectedTime,
    selectedConsultant: "",
  };

  const [calendarData, setCalendarData] = useState(defaultCalendarData);
  const updateCalendarData = (deltaData) => {
    setCalendarData((prevState) => ({
      ...prevState,
      ...deltaData,
    }));
  };

  /**
   * get monthly
   */
  useEffect(() => {
    if (
      appointmentSource !== AppointmentSource.CALENDAR ||
      !calendarData.month ||
      !calendarData.year
      /**
       * sessionType, location mit prüfen
       */
    ) {
      //       console.debug(
      //         `monthly refuse
      // appointmentSource: %o,
      // calendarData.month: %o
      // calendarData.year: %o`,
      //         appointmentSource,
      //         calendarData.month,
      //         calendarData.year
      //       );
      return updateCalendarData({
        unavailableDaysOfMonth: [],
        firstDayOfMonth: "",
        lastDayOfMonth: "",
        monthlyState: WSState.IDLE,
      });
    }

    // console.debug(
    //   "monthly OK year: %o, month: %o",
    //   calendarData.year,
    //   calendarData.month
    // );

    updateCalendarData({ monthlyState: WSState.LOADING });
    // Fetching Monthly Data: non-available days
    fetch(
      `${restHost}/anfrage/monthly/${calendarData.month}/${calendarData.year}/${sessionType}/${location}`
    )
      .then((response) => response.json())
      .then((json) => {
        updateCalendarData({
          unavailableDaysOfMonth: json[0].remove_days,
          firstDayOfMonth: json[0].first_day,
          lastDayOfMonth: json[0].last_day,
          monthlyState: WSState.DONE,
        });
      })
      .catch((error) => {
        // console.log("get monthly: %o", error);
        updateCalendarData({
          unavailableDaysOfMonth: [],
          firstDayOfMonth: "",
          lastDayOfMonth: "",
          monthlyState: WSState.ERROR,
        });
      });
  }, [
    appointmentSource,
    calendarData.month,
    calendarData.year,
    location,
    sessionType,
  ]);

  /**
   * Get the daily calendar data.
   */
  useEffect(() => {
    if (
      appointmentSource !== AppointmentSource.CALENDAR ||
      !location ||
      !calendarData.selectedDay ||
      !sessionType
    ) {
      //       console.debug(
      //         `daily refuse
      // appointmentSource: %o,
      // location: %o,
      // calendarData.selectedDay: %o,
      // sessionType: %o`,
      //         appointmentSource,
      //         location,
      //         calendarData.selectedDay,
      //         sessionType
      //       );
      return updateCalendarData({
        dailyHours: [],
        dailyHoursState: WSState.IDLE,
      });
    }

    updateCalendarData({
      dailyHoursState: WSState.LOADING,
    });

    // Fetching Dayly Data
    fetch(
      `${restHost}/anfrage/get/${calendarData.selectedDay}/${sessionType}/${location}`
    )
      .then((response) => response.json())
      .then((json) => {
        updateCalendarData({
          dailyHours: json,
          dailyHoursState: WSState.DONE,
        });
      })
      .catch((error) => {
        // console.log("get daily: %o", error);
        updateCalendarData({
          dailyHours: [],
          dailyHoursState: WSState.ERROR,
        });
      });
  }, [appointmentSource, location, calendarData.selectedDay, sessionType]);

  /**
   * Function for the Datepicker:
   * Get the Clicked Day and fetches the data for the day.
   *
   * @param date
   */
  const handleDayChange = (date) => {
    updateCalendarData({
      selectedDay: moment(date).format("YYYY-MM-DD"),
      selectedTime: "",
    });
  };

  /**
   * Function for the Datepicker:
   * Get the Clicked Month and fetches the data for the month.
   * @param date
   */
  const handleMonthChange = (date) => {
    updateCalendarData({
      month: moment(date).format("MM"),
      year: moment(date).format("YYYY"),
      unavailableDaysOfMonth: [],
    });
  };

  const submitForm = () => {
    const submitValue = {
      day: calendarData.selectedDay,
      time: calendarData.selectedTime,
      consultant: calendarData.selectedConsultant,
    };
    // console.log("SUBMIT FORM ", JSON.stringify(submitValue, null, 4));
    handleChange(submitValue);
  };

  const showLoading = () => {
    if (appointmentSource !== AppointmentSource.CALENDAR) return false;
    if (calendarData.monthlyState === WSState.LOADING) return true;
    if (calendarData.dailyHoursState === WSState.LOADING) return true;
    return false;
  };

  const showError = () => {
    if (appointmentSource !== AppointmentSource.CALENDAR) return false;
    if (calendarData.monthlyState === WSState.ERROR) return true;
    if (calendarData.dailyHoursState === WSState.ERROR) return true;
    return false;
  };

  const showCalendar = () => {
    // console.log(
    //   `%c
    // appointmentSource: %o,
    // calendarData.monthlyState: %o
    // `,
    //   "background-color: yellow; font-weight: bold; padding: 6px;",
    //   appointmentSource,
    //   calendarData.monthlyState
    // );
    if (appointmentSource !== AppointmentSource.CALENDAR) return false;
    if (calendarData.monthlyState !== WSState.DONE) return false;
    return true;
  };

  const calcOpenToDate = () => {
    if (!calendarData.year) return null;
    if (!calendarData.month) return null;

    return moment(`${calendarData.year}-${calendarData.month}-15`).toDate();
  };

  // map times and make them implicitly unique
  const timesToCheck = arrayUnique(
    calendarData.dailyHours.map((item) => item.time)
  );

  let { currentLanguage } = useSelector((reduxStore) => ({
    currentLanguage: reduxStore.i18n.currentLanguage,
  }));

  const todayEOD = moment().endOf("day");

  if (calendarData.dailyHours.length > 0) {
    moment.locale(currentLanguage);
  }

  // if (showCalendar()) {
  //   console.log(
  //     `
  //   BENO
  //   locale=%o
  //   selected=%o
  //   excludeDates=%o
  //   openToDate=%o
  //   minDate=%o
  //   `,
  //     currentLanguage,
  //     calendarData.selectedDay,
  //     calendarData.unavailableDaysOfMonth,
  //     calcOpenToDate(),
  //     moment(calendarData.firstDay)
  //   );
  // }

  return (
    <>
      {showLoading() && <LoadingMarkup />}

      {showError() && <FormError />}

      {showCalendar() && (
        <>
          <DatePicker
            className="form-control"
            locale={currentLanguage}
            dateFormat={"DD.MM.YYYY"}
            dropdownMode="select"
            inline
            onChange={handleDayChange}
            onMonthChange={handleMonthChange}
            onChangeRaw={(e) => {
              e.preventDefault();
            }}
            minDate={moment().add(1, "day").toDate()}
            excludeDates={calendarData.unavailableDaysOfMonth}
            openToDate={calcOpenToDate()}
            selected={
              calendarData.selectedDay
                ? moment(calendarData.selectedDay).toDate()
                : ""
            }
          ></DatePicker>

          <Formik
            enableReinitialize
            initialValues={{
              time: calendarData.selectedTime,
            }}
            validate={(values) => {
              // console.log("<Formik validate values: %o", values);
              const errors = {};
              if (!values.time) {
                if (!calendarData.selectedDay) {
                  errors.time = t("validator.day-time.missing");
                } else {
                  errors.time = t("validator.time.missing");
                }
              }
              return errors;
            }}
            onSubmit={submitForm}
          >
            {({
              values,
              initialValues,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              setSubmitting,
              isSubmitting,
            }) => {
              return (
                <Form>
                  <div className="form-group">
                    <label htmlFor="time">
                      {calendarData.selectedDay &&
                        moment(calendarData.selectedDay).format(
                          "dddd, DD.MM.YYYY"
                        )}
                      {!calendarData.selectedDay && t("date.choose.day")}
                    </label>
                    <Field
                      as="select"
                      name="time"
                      id="appointment-time-choose"
                      className="appointment-select appointment-select--time"
                      onChange={(event) => {
                        updateCalendarData({
                          selectedTime: event.target.value,
                          selectedConsultant:
                            event.target[
                              event.target.selectedIndex
                            ].getAttribute("data-uid"),
                        });
                      }}
                    >
                      <option value="">{t("Uhrzeit")}</option>
                      {timesToCheck.map((time, index) => {
                        let selectedDayandTime = `${calendarData.selectedDay}T${time}`;
                        if (moment(selectedDayandTime).isAfter(todayEOD)) {
                          const matchingItems = calendarData.dailyHours.filter(
                            (item) => time == item.time && item.free
                          );
                          // Use first uid from matchingItems: matchingItems[0].uid
                          return (
                            <option
                              data-uid={
                                matchingItems.length > 0
                                  ? matchingItems[0].uid
                                  : 0
                              }
                              value={time.time}
                              key={index}
                              data-index={index}
                              disabled={matchingItems.length === 0}
                            >
                              {time}
                            </option>
                          );
                        }
                      })}
                    </Field>
                  </div>
                  <ErrorMessage name="time">
                    {(error) => <p className="text-danger">{error}</p>}
                  </ErrorMessage>

                  <div className="button-grp">
                    <button
                      className="next appointment-button appointment-button--green"
                      type="submit"
                    >
                      {t("next")}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
};;

FormPhaseDateCalendar.propTypes = {
  location: PropTypes.string.isRequired,
  sessionType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  appointmentSource: PropTypes.object.isRequired,
  setAppointmentSource: PropTypes.func.isRequired,
  selectedDay: PropTypes.string,
  selectedTime: PropTypes.string,
};

export default FormPhaseDateCalendar;
