import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

// Moment
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

// Form parts.
import FormPhaseLocation from "./phases/form-phase-location";
import FormPhaseType from "./phases/form-phase-type";
import FormPhaseDate from "./phases/form-phase-date";
import FormPhasePersonalInfo from "./phases/form-phase-personal-info";
import FormPhaseTattoo from "./phases/form-phase-tattoo";
import FormPhaseSuccess from "./phases/form-phase-success";
import { restHost } from "../../config";
import _, { isNull } from "lodash";
// import validator from "validator";
// import { loadingAction } from "../../actions";
import scrollToElement from "scroll-to-element";
import PropTypes from "prop-types";
import AppointmentSource from "./types/appointment-source";
import WSState from "./types/ws-state";
import qs from "qs";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../app-actions";

const InquiryForm = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const t = (translation_key) => intl.formatMessage({ id: translation_key });

  const [phase, setPhase] = useState(1);
  const PHASE_SUBMIT = 4;
  const PHASE_LAST = 5;

  const [createdData, setCreatedData] = useState(null);
  const [formErrors, setFormErrors] = useState(null);

  // Get the Inquiry Source from the URL parameter
  const getInquirySource = () => {
    const queryParams = qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    });

    const sourceIdPara = "aqid";

    if (sourceIdPara in queryParams) {
      return parseInt(queryParams[sourceIdPara]);
    }

    return null;
  };

  const defaultInquiryData = {
    location: "",
    firstname: "",
    lastname: "",
    email: "",
    privacy: false,
    phone: "",
    day: "",
    time: "",
    sessionType: null,
    consultant: null,
    source: getInquirySource(),
    submit: false,
  };

  const [inquiryData, setInquiryData] = useState(defaultInquiryData);

  const handleChange = (deltaData, phase) => {
    // console.log("handleChange deltaData: ", deltaData);
    // console.log("handleChange phase: ", phase);

    // flow control: set submit === true in inquiryData if the current phase is the submit phase
    const phaseData = {
      submit: phase === PHASE_SUBMIT,
    };

    setInquiryData((prevState) => ({
      ...prevState,
      ...deltaData,
      ...phaseData,
    }));

    if (phase !== PHASE_SUBMIT) {
      return setPhase(phase + 1);
    }
  };

  useEffect(() => {
    if (!inquiryData.submit) {
      return;
    }
    handleSubmit();
  }, [inquiryData.submit]);

  const createChangeHandler = (phase) => {
    return (deltaData) => {
      handleChange(deltaData, phase);
    };
  };

  const handleSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    // console.log("handleSubmit - e=%o", e);

    const drupalFormat = "YYYY-MM-DDTHH:mm:ss";
    const formatWithoutTime = "YYYY-MM-DD";

    let time = `${moment(inquiryData.day).format(formatWithoutTime)}T${
      inquiryData.time
    }:00`;

    const payload = {
      field_anfrage_vorname: inquiryData.firstname,
      field_anfrage_nachname: inquiryData.lastname,
      field_anfrage_email: inquiryData.email,
      field_datenschutz_best_tigt: inquiryData.privacy ? "1" : "0",
      field_filiale: inquiryData.location,
      field_anfrage_telefonnumer: inquiryData.phone,
      field_anfrage_anmerkung: "",
      field_anfrage_vorstellungen: [],
      field_termin_date: moment(time).utc().format(drupalFormat),
      field_place: inquiryData.sessionType,
      field_account: "",
      field_berater: inquiryData.consultant,
      field_source: inquiryData.source,
    };

    // Start Loading Animation.
    dispatch(loadingAction(true, "Processing your Request..."));

    fetch(`${restHost}/anfrage/create?XDEBUG_SESSION_START=PHPSTORM`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.status === 200) {
          const tmpCreatedData = await response.json();

          // End Loading Animation.
          dispatch(loadingAction(false));

          setCreatedData(tmpCreatedData);

          try {
            // ### TRACKING ###
            if (typeof this.goog_report_conversion !== "undefined") {
              this.goog_report_conversion(
                "http://www.inklabs.de/terminanfrage"
              );
            }

            // Facebook Pixel
            if (typeof fbq !== "undefined") {
              fbq("track", "Lead");
            }
            if (typeof gtag !== "undefined") {
              gtag("event", "conversion", {
                send_to: "AW-874085602/quKeCLeUi2oQ4vnloAM",
              });
            }
            if (typeof ga !== "undefined") {
              ga("send", "event", "Formular", "Gesendet");
            }
          } catch (e) {
            console.error("External Tracking %o", e);
          }

          setPhase(phase + 1);
        } else if (response.status === 403) {
          // End Loading Animation.
          dispatch(loadingAction(false));

          // Show success alert.
          this.props.alert.show(
            this.props.t(
              `Hey ${this.state.fields.forename}! Der Termin ist leider schon weg! Bitte such dir einen anderen aus`
            ),
            { type: "error" }
          );
        } else {
          setFormErrors(decodeURI(response.statusText));
        }
      })
      .catch((error) => {
        setFormErrors(decodeURI(error));
      });
  };

  const goBack = () => setPhase(phase - 1);

  return (
    <div className="appointment-form">
      <div className="background-image">
        <div className="wrapper">
          <img src="/img/inklabs_sw_klein.png" alt="inklabs-logo" />
        </div>
      </div>
      {phase && phase > 1 && phase < PHASE_LAST && (
        <button
          className="appointment-backbutton"
          onClick={goBack}
          title={t("go.back")}
        >
          <img src="/img/go_back.svg" alt={t("go.back")} />
        </button>
      )}

      <div className="container">
        <div className="row">
          <div className="center-col col-lg-8 col-md-10 col-sm-12">
            {(() => {
              switch (phase) {
                case 1:
                  return (
                    <FormPhaseLocation
                      handleChange={createChangeHandler(phase)}
                      selectedLocation={inquiryData.location}
                    />
                  );

                case 2:
                  return (
                    <FormPhaseType
                      handleChange={createChangeHandler(phase)}
                      selectedSessionType={inquiryData.sessionType}
                    />
                  );

                case 3:
                  return (
                    <FormPhaseDate
                      handleChange={createChangeHandler(phase)}
                      location={inquiryData.location}
                      selectedDay={inquiryData.day}
                      selectedTime={inquiryData.time}
                      sessionType={inquiryData.sessionType}
                    />
                  );

                case 4:
                  return (
                    <FormPhasePersonalInfo
                      handleChange={createChangeHandler(phase)}
                      firstname={inquiryData.firstname}
                      lastname={inquiryData.lastname}
                      email={inquiryData.email}
                      privacy={inquiryData.privacy}
                      phone={inquiryData.phone}
                    />
                  );

                case 5:
                  return <FormPhaseSuccess createdData={createdData} />;

                default:
                  return (
                    <h3>
                      Default Phase: {phase} - {inquiryData.location}
                    </h3>
                  );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

InquiryForm.propTypes = { location: PropTypes.object };

export default InquiryForm;
