import React from "react";
import ReactLoading from "react-loading";

const LoadingMarkup = () => {
  return (
    <div id="loading">
      <ReactLoading
        type={"spin"}
        color={"black"}
        height={"50px"}
        width={"50px"}
      />
      <span>Loading...</span>
    </div>
  );
};

export default LoadingMarkup;
