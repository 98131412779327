import React from "react";
import GetSessionTypes from "../queries/GetAppointmentTypes";
import { useIntl, FormattedMessage } from "react-intl";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import classNames from "classnames";

import LoadingMarkup from "../components/loadingmarkup";
import FormError from "../components/form-error";

const machineName = (name) => {
  return `${name}`.toLowerCase().replaceAll(/[^-\w]/g, "_");
};

const FormPhaseType = ({ handleChange, selectedSessionType }) => {
  const intl = useIntl();
  const t = (translation_key) => intl.formatMessage({ id: translation_key });
  const { loading, error, data } = useQuery(GetSessionTypes);

  const onTypeClick = (typeId) => {
    handleChange({ sessionType: typeId });
  };

  if (loading) {
    return <LoadingMarkup />;
  }

  if (error) {
    return <FormError />;
  }

  return (
    <div className="appointment-phase-type">
      <h1>{t("counseling-type.headline")}</h1>
      <p>
        <span className="appointment-text-bold">
          {t("counseling-type.intro.1")}
        </span>
        <br />
        {t("counseling-type.intro.2")}
      </p>
      {data.entityQuery.items.length !== 0 && (
        <ul className="appointment-type-list row">
          {data.entityQuery.items.map((item, index) => {
            return (
              <li
                className="col-md-6 col-12"
                key={index}
                onClick={(event) => {
                  event.preventDefault();
                  onTypeClick(item.id);
                }}
              >
                <div
                  className={
                    classNames({
                      "appointment-type": true,
                      "appointment-tile": true,
                      selected: item.id === selectedSessionType,
                    }) + ` appointment-type--${machineName(item.name)}`
                  }
                >
                  <span className="label">
                    {t("counseling-type.label." + machineName(item.name))}
                  </span>
                </div>
              </li>
            );
          })}
        </ul>
      )}

      {data.entityQuery.items.length === 0 && (
        <div className="no-results">
          {t("Keine Arten verfügbar... Bitte kontaktiere Uns.")}
        </div>
      )}
      <div />
    </div>
  );
};

FormPhaseType.propTypes = {
  handleChange: PropTypes.func,
  selectedSessionType: PropTypes.string,
};

export default FormPhaseType;
