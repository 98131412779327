import React from "react";

const FormPhaseSuccess = (props) => {
  React.useEffect(() => {
    window.location.replace("https://www.inklabs.de/dein-termin/bestaetigung");
  }, []);

  // return <h3>{JSON.stringify(props)}</h3>;

  return null;
};;

export default FormPhaseSuccess;
