import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import PropTypes from "prop-types";
import classNames from "classnames";
import AppointmentSource from "../types/appointment-source";
import WSState from "../types/ws-state";
import { restHost } from "../../../config";
import FormError from "./form-error";
import { isArray, isNull } from "lodash";
import LoadingMarkup from "./loadingmarkup";
import { useSelector } from "react-redux";

const FormPhaseDateSuggestions = ({
  location,
  sessionType,
  handleChange,
  appointmentSource,
  setAppointmentSource,
  selectedDay,
  selectedTime,
}) => {
  const intl = useIntl();
  const t = (translation_key) => intl.formatMessage({ id: translation_key });

  const defaultSuggestionData = {
    suggestions: [],
    suggestionsVersion: Date.now(),
    suggestionsState: WSState.IDLE,
  };

  const [suggestionData, setSuggestionData] = useState(defaultSuggestionData);
  const updateSuggestionData = (deltaData) => {
    setSuggestionData((prevState) => ({
      ...prevState,
      ...deltaData,
    }));
  };

  //
  useEffect(() => {
    // console.debug(
    //   "useEffect suggestions\ninquiryData.location: %s\ninquiryData.sessionType: %s\nappointmentSource: %o\nappointmentsource!==AppointmentSource.SUGGESTIONS",
    //   location,
    //   sessionType,
    //   appointmentSource,
    //   appointmentSource !== AppointmentSource.SUGGESTIONS
    // );
    if (
      !location ||
      !sessionType ||
      appointmentSource !== AppointmentSource.SUGGESTIONS
    ) {
      // reset suggestions if need be
      if (!isNull(suggestionData.suggestions))
        updateSuggestionData({
          suggestions: null,
          suggestionsState: WSState.IDLE,
        });
      return;
    }

    updateSuggestionData({ suggestionsState: WSState.LOADING });

    fetch(`${restHost}/anfrage/suggestions/${sessionType}/${location}`)
      .then((response) => response.json())
      .then((json) => {
        if (!isArray(json)) {
          throw new Error("Unexpected result type.");
        }
        if (json.length === 0) {
          console.info("No suggestion returned => fall back to calendar");
          updateSuggestionData({
            suggestions: json,
            suggestionsState: WSState.EMPTY,
          });
          setAppointmentSource(AppointmentSource.CALENDAR);
        } else {
          updateSuggestionData({
            suggestions: json,
            suggestionsState: WSState.DONE,
          });
        }
      })
      .catch((error) => {
        console.error("ERROR fetchSuggestions: %o", error);
        updateSuggestionData({
          suggestions: null,
          suggestionsState: WSState.ERROR,
        });
        setAppointmentSource(AppointmentSource.CALENDAR);
      });
    // .finally(() => {
    //   console.log(
    //     "%csuggestions: %o",
    //     "backgroundColor: aqua",
    //     suggestionData
    //   );
    // });
  }, [
    location,
    sessionType,
    suggestionData.suggestionsVersion,
    appointmentSource,
  ]);

  const showLoading = () => {
    if (appointmentSource !== AppointmentSource.SUGGESTIONS) return false;
    return suggestionData.suggestionsState === WSState.LOADING;
  };

  const showError = () => {
    if (appointmentSource !== AppointmentSource.SUGGESTIONS) return false;
    return suggestionData.suggestionsState === WSState.ERROR;
  };

  const showSuggestions = () => {
    if (appointmentSource !== AppointmentSource.SUGGESTIONS) return false;
    if (suggestionData.suggestionsState == WSState.DONE) return true;
    return false;
  };

  const setSuggestionSlot = (slot, suggestionDate) => {
    const submitValue = {
      day: suggestionDate,
      time: slot.time,
      consultant: slot.uid,
    };
    // console.log("SUBMIT FORM ", JSON.stringify(submitValue, null, 4));
    handleChange(submitValue);
  };

  let { currentLanguage } = useSelector((reduxStore) => ({
    currentLanguage: reduxStore.i18n.currentLanguage,
  }));

  moment.locale(currentLanguage);

  return (
    <>
      {showLoading() && <LoadingMarkup />}

      {showError() && <FormError />}

      {showSuggestions() && (
        <>
          {suggestionData.suggestions.length > 0 ? (
            <div className="appointment-suggestions">
              <div className="row">
                {suggestionData.suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="appointment-suggestion col-12 col-md-4"
                  >
                    <h3>{moment(suggestion.date).format("dddd, D.M.YYYY")}</h3>
                    {suggestion.slots
                      .sort((a, b) => parseInt(a.time) - parseInt(b.time))
                      .map((slot, slotIndex) => (
                        <div className="slot" key={slotIndex}>
                          <div
                            className={classNames({
                              "slot-inner": true,
                              active:
                                slot.time === selectedTime &&
                                suggestion.date === selectedDay,
                            })}
                            onClick={() =>
                              setSuggestionSlot(slot, suggestion.date)
                            }
                          >
                            <h4>
                              {slot.time} {t("Uhr")}
                            </h4>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <button
                    className="appointment-button appointment-button--show-calendar"
                    onClick={() =>
                      setAppointmentSource(AppointmentSource.CALENDAR)
                    }
                  >
                    {t("Keiner dieser Vorschläge passt mir..")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {/* TODO: No suggestions found message + button: "Go to calendar" */}
              {/* or go automatically to calendar? */}
              <p>404</p>
            </>
          )}
        </>
      )}
    </>
  );
};

FormPhaseDateSuggestions.propTypes = {
  location: PropTypes.string.isRequired,
  sessionType: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  appointmentSource: PropTypes.object.isRequired,
  setAppointmentSource: PropTypes.func.isRequired,
  selectedDay: PropTypes.string,
  selectedTime: PropTypes.string,
};

export default FormPhaseDateSuggestions;
