import gql from "graphql-tag";

const GetLocations = gql`
  query filialen {
    entityQuery(
      entityType: TAXONOMY_TERM
      limit: 99
      offset: 0
      filter: {
        conditions: [
          { operator: EQUAL, field: "vid", value: ["filialen"] }
          { operator: IN, field: "field_show_in_customer_form", value: ["1"] }
        ]
      }
    ) {
      items {
        id
        label
        ... on TaxonomyTermFilialen {
          fieldFrontendImage {
            alt
            derivative(style: FRONTEND_FORM_IMAGE) {
              urlPath
            }
          }
          fieldShowInCustomerForm
        }
      }
    }
  }
`;

export default GetLocations;
