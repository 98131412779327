class AppointmentSource {
  // Create new instances of the same class as static attributes
  static SUGGESTIONS = new AppointmentSource("suggestions");
  static CALENDAR = new AppointmentSource("calendar");

  constructor(name) {
    this.name = name;
  }
}

export default AppointmentSource;
