const appReducerDefaultState = {
    client: "",
    count: 0,
    loading: false,
    loadingMsg: null,
    mobile: true,
    sessionToken: "",
  },
  appReducer = (state = appReducerDefaultState, action) => {
    switch (action.type) {
      case "SET_SESSION_TOKEN":
        return Object.assign({}, state, { sessionToken: action.token });

      case "LOADING":
        return Object.assign({}, state, {
          loading: action.loading,
          loadingMsg: action.msg,
        });

      case "CLIENT":
        return Object.assign({}, state, { client: action.client });

      case "MOBILE":
        return Object.assign({}, state, { mobile: action.mobile });

      default:
        return state;
    }
  };

export { appReducerDefaultState, appReducer };