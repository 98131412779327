import React from "react";
import { connect } from "react-redux";

// 3rd Party
import ReactLoading from "react-loading";

const mapStateToProps = (state) => ({
    loading: state.appStore.loading,
    msg: state.appStore.loadingMsg,
  }),
  Loading = ({ loading, msg }) => (
    <div>
      {loading && (
        <div id="global-loading">
          <ReactLoading type={"spin"} color={"#000"} height={75} width={75} />
          {msg && (
            <div className="loading-label">
              <p>{msg}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );

export default connect(mapStateToProps)(Loading);
