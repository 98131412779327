import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import AppointmentSource from "../types/appointment-source";
import FormPhaseDateSuggestions from "../components/form-phase-date-suggestions";
import FormPhaseDateCalendar from "../components/form-phase-date-calendar";

// Moment
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

const FormPhaseDate = ({
  location,
  sessionType,
  handleChange,
  selectedTime,
  selectedDay,
}) => {
  const intl = useIntl();
  const t = (translation_key) => intl.formatMessage({ id: translation_key });

  const [appointmentSource, setAppointmentSource] = useState(
    AppointmentSource.SUGGESTIONS
  );

  return (
    <div className="form-section form-date">
      <h1>{t("date.headline")}</h1>
      <p>
        {t("date.intro.1")} {t("date.intro.2")}
      </p>

      {appointmentSource === AppointmentSource.SUGGESTIONS && (
        <FormPhaseDateSuggestions
          appointmentSource={appointmentSource}
          handleChange={handleChange}
          location={location}
          selectedDay={selectedDay}
          selectedTime={selectedTime}
          sessionType={sessionType}
          setAppointmentSource={setAppointmentSource}
        />
      )}

      {appointmentSource === AppointmentSource.CALENDAR && (
        <FormPhaseDateCalendar
          appointmentSource={appointmentSource}
          handleChange={handleChange}
          location={location}
          selectedDay={selectedDay}
          selectedTime={selectedTime}
          sessionType={sessionType}
          setAppointmentSource={setAppointmentSource}
        />
      )}
    </div>
  );
};

FormPhaseDate.propTypes = {
  handleChange: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  selectedDay: PropTypes.string,
  selectedTime: PropTypes.string,
  sessionType: PropTypes.string.isRequired,
};

export default FormPhaseDate;
