const searchOpenAction = (searchOpen) => ({
    searchOpen,
    type: "SEARCH_OPEN",
  }),
  loadingAction = (loading, msg = null) => ({
    loading,
    msg,
    type: "LOADING",
  });

export { searchOpenAction, loadingAction };
